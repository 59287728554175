import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { projectApi, metadataApi } from './services';
import authReducer from './authentication/authSlice';
import authorizationReducer from './authorization/authorizationSlice';
import projectReducer from './pages/project/projectSlice';
import taskReducer from './pages/task/taskSlice';
import customerReducer from './pages/common/Customer/customerSlice';

// combine all reducers
const rootReducer = combineReducers({
	[projectApi.reducerPath]: projectApi.reducer,
	[metadataApi.reducerPath]: metadataApi.reducer,
	authentication: authReducer,
	authorization: authorizationReducer,
	project: projectReducer,
	tasks: taskReducer,
	customer: customerReducer,
});

// define root store
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			projectApi.middleware,
			metadataApi.middleware
		),
});

// define type of store dispatch
// type AppDispatch = typeof store.dispatch;
// define custom dispatch method which inturn calls typed redux dispatch
// export const useAppDispatch = () => useDispatch<AppDispatch>();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
