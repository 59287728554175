export default function Backdrop(theme) {
	return {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: theme.zIndex.drawer + 1,
					color: theme.palette.common.white,
				},
			},
		},
	};
}
