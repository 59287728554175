/* eslint-disable */
/* Third party control code, cannot fix all issues */
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, validatePackage } from '@progress/kendo-react-common';
import {
	provideLocalizationService,
	registerForLocalization,
} from '@progress/kendo-react-intl';
import { labelsOptional, messages } from './messages';
import { packageMetadata } from './package-metadata';

/**
 * Represents the props of the KendoReact FloatingLabel component.
 */
export interface FloatingLabelProps {
	/**
	 * Represent the [`htmlFor`](https://reactjs.org/docs/dom-elements.html#htmlfor) property, which will be set to the `label` element.
	 */
	/* tslint:enable:max-line-length */
	editorId?: string;
	/**
	 * Specifies the value of the editor. Used to define if the editor is empty.
	 */
	editorValue?: any;
	/**
	 * Specifies the placeholder of the editor. Used to define if the editor is empty.
	 */
	editorPlaceholder?: string;
	/**
	 * Specifies if the validity of the editor. Used to define the editor is invalid.
	 */
	editorValid?: boolean;
	/**
	 * Specifies if the editor is disabled.
	 */
	editorDisabled?: boolean;
	/**
	 * Adds a floating label that describes the editor.
	 */
	label?: string;
	/**
	 * The styles that are applied to the FloatingLabel.
	 */
	style?: React.CSSProperties;
	/**
	 * Sets a class of the FloatingLabel DOM element.
	 */
	className?: string;
	/**
	 * Specifies the direction of the label.
	 */
	dir?: string;
	/**
	 * Represents the id of the label element.
	 * The value should be also set to the editor's `ariaLabelledBy` property.
	 * Can be used when the editor is not containing native form element.
	 */
	id?: string;
	/**
	 * If enabled, marks the label as optional.
	 */
	optional?: boolean;
}

/**
 * @hidden
 */
export interface FloatingLabelState {
	focused?: boolean;
}

/**
 * Represents the KendoReact FloatingLabel component.
 *
 * @example
 * ```jsx
 * const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];
 * const App = () => {
 *     const [ddlState, setDdlState] = React.useState();
 *     const editorId = 'ddl-sizes';
 *     return (
 *         <FloatingLabel label={'Shirt Size:'} editorId={editorId} editorValue={ddlState}>
 *             <DropDownList
 *                 id={editorId}
 *                 value={ddlState}
 *                 data={sizes}
 *                 onChange={(e) => setDdlState(e.target.value)}
 *             />
 *         </FloatingLabel>
 *     );
 * };
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export class FloatingLabel extends React.Component<
	FloatingLabelProps,
	FloatingLabelState
> {
	/**
	 * @hidden
	 */
	static propTypes = {
		label: PropTypes.string,
		editorId: PropTypes.string,
		editorValue: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.number,
		]),
		editorPlaceholder: PropTypes.string,
		editorValid: PropTypes.bool,
		editorDisabled: PropTypes.bool,
		id: PropTypes.string,
		style: PropTypes.object,
		className: PropTypes.string,
		optional: PropTypes.bool,
	};

	/**
	 * @hidden
	 */
	readonly state: FloatingLabelState = {
		focused: false,
	};

	constructor(props: FloatingLabelProps) {
		super(props);
		validatePackage(packageMetadata);
	}

	/**
	 * @hidden
	 */
	handleFocus = (_: React.SyntheticEvent<HTMLSpanElement>): void => {
		this.setState({ focused: true });
	};

	/**
	 * @hidden
	 */
	handleBlur = (_: React.SyntheticEvent<HTMLSpanElement>): void => {
		this.setState({ focused: false });
	};

	/**
	 * @hidden
	 */
	render() {
		const {
			label,
			editorId,
			className,
			editorValue,
			editorPlaceholder,
			editorValid,
			editorDisabled,
			style,
			id,
			optional,
		} = this.props;
		const localizationService = provideLocalizationService(this);
		const localizedOptional = optional
			? localizationService.toLanguageString(
					labelsOptional,
					messages[labelsOptional]
			  )
			: '*';
		const optionalElement = localizedOptional && (
			<span className="k-label-optional">{localizedOptional}</span>
		);

		const spanClassNames = classNames(
			{
				'k-textbox-container': true,
				'k-state-focused': this.state.focused,
				'k-state-empty':
					!editorPlaceholder && !editorValue && editorValue !== 0,
				'k-text-disabled': editorDisabled,
				'k-rtl': this.props.dir === 'rtl',
			},
			className
		);
		const labelClassNames = classNames({
			'k-label': true,
			'k-text-error': editorValid === false,
			'k-text-disabled': editorDisabled,
		});
		return (
			<span
				className={spanClassNames}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
				style={style}
				dir={this.props.dir}
			>
				{this.props.children}
				{label ? (
					editorId ? (
						<label id={id} htmlFor={editorId} className={labelClassNames}>
							{label}
							{optionalElement}
						</label>
					) : (
						<span id={id} className={labelClassNames}>
							{label}
							{optionalElement}
						</span>
					)
				) : null}
			</span>
		);
	}
}

registerForLocalization(FloatingLabel);
