import React, { FC, useEffect } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import ThemeConfig from './theme';
import Routes from './routes';
import store from './store';
import userManager, {
	loadUserFromStorage,
} from './authentication/userManagerService';
import AuthProvider from './authentication/authProvider';

const App: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		// fetch current user from cookies and kick off login if there is no user
		loadUserFromStorage(dispatch);
	}, [dispatch]);

	return (
		<AuthProvider userManager={userManager} store={store}>
			<ThemeConfig>
				<Routes />
			</ThemeConfig>
		</AuthProvider>
	);
};

export default App;
