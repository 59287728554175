import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { storeUser, removeUser } from './authSlice';

export default function AuthProvider({
	userManager: manager,
	store,
	children,
}) {
	const dispatch = useDispatch();

	const userManager = useRef();

	useEffect(() => {
		userManager.current = manager;

		const onUserLoaded = (user) => {
			dispatch(storeUser(user.toStorageString()));
		};

		const onUserUnloaded = () => {
			dispatch(removeUser());
		};

		const onAccessTokenExpiring = () => {};

		const onAccessTokenExpired = () => {};

		// events for user
		userManager.current.events.addUserLoaded(onUserLoaded);
		userManager.current.events.addUserUnloaded(onUserUnloaded);
		userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
		userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);

		// Specify how to clean up after this effect:
		return function cleanup() {
			userManager.current.events.removeUserLoaded(onUserLoaded);
			userManager.current.events.removeUserUnloaded(onUserUnloaded);
			userManager.current.events.removeAccessTokenExpiring(
				onAccessTokenExpiring
			);
			userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired);
		};
	}, [manager, store, dispatch]);

	return React.Children.only(children);
}
