import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DefaultLayout from './layouts/default';
import Login from './pages/Login';
import SigninOidc from './pages/SigninOidc';
import SilentRenewOidc from './pages/SilentRenewOidc';
import TaskDetails from './pages/task/Task';
import ProjectDetails from './pages/project/Project';
import { RootState } from './store';
import CloseWindow from './pages/CloseWindow';

export default function Router() {
	const user = useSelector((state: RootState) => state.authentication.user);
	let destination;

	const ignoredRoutes = ['/login', '/silent-renew-oidc', '/signin-oidc'];
	if (!ignoredRoutes.includes(window.location.pathname)) {
		destination = window.location.pathname + window.location.search;
	}

	// routes specific to login, only accessible to users who are not logged in
	const publicLoginRoutes = [
		{
			path: '/signin-oidc',
			element: <SigninOidc />,
		},
		{
			path: '/login',
			element: <Login />,
		},
	];

	// Public routes the user should have access to regardless if they are logged in or not
	const publicRoutes = [];

	// Private routes only accessible to users who are logged in
	const privateRoutes = [
		{
			path: '/silent-renew-oidc',
			element: <SilentRenewOidc />,
		},
		{
			path: '/',
			element: <DefaultLayout />,
			children: [
				// { path: '/', element: <Navigate to="/project" replace /> },
				{ path: '/project', element: <ProjectDetails /> },
				{ path: '/task', element: <TaskDetails /> },
				{ path: '/closewindow', element: <CloseWindow /> },
			],
		},

		// Redirect invalid path to root page, /databases
		// TODO: In the future, maybe there should be a 404 Not Found page
		{ path: '*', element: <Navigate to="/" replace /> },
	];

	const publicRouter = useRoutes([
		...publicLoginRoutes,
		...publicRoutes,
		{
			path: '*',
			element: (
				<Navigate
					to={destination ? `/login?destination=${destination}` : '/login'}
					replace
				/>
			),
		},
	]);

	const privateRouter = useRoutes([
		...publicLoginRoutes,
		...publicRoutes,
		...privateRoutes,
	]);

	return user ? privateRouter : publicRouter;
}
