import React, { FC } from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';

const CloseWindow: FC = () => {
	return (
		<div>
			<div>
				<Form
					render={() => (
						<FormElement style={{ maxWidth: 1200 }}>
							All Tasks have been created successfully. This window can now be
							closed. Thank you!
						</FormElement>
					)}
				/>
			</div>
		</div>
	);
};

export default CloseWindow;
