import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from './store';
import './locale';
import App from './App';
import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<BrowserRouter>
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
