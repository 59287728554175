import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import {
	ThemeProvider,
	createTheme,
	StyledEngineProvider,
} from '@material-ui/core/styles';

import './theme.css';
import palette from './palette';
import typography from './typography';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';

const ThemeConfig = ({ children }) => {
	const themeOptions = useMemo(
		() => ({
			palette,
			typography,
		}),
		[]
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

ThemeConfig.propTypes = {
	children: PropTypes.node,
};

ThemeConfig.defaultProps = {
	children: PropTypes.node,
};

export default ThemeConfig;
