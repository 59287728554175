/**
 * @hidden
 */
export const labelsOptional = 'labels.optional';

/**
 * @hidden
 */
export const messages = {
	[labelsOptional]: '',
};
