import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '../common/Customer/customerSlice';

export interface Project {
	customer: Customer[];
	billto: Customer[];
	businessUnit: Customer[];
	brand: Customer[];
	projectTypeData: any;
	projectTypeValue: any;
	projectTemplateData: any;
	projectTemplateValue: any;
	channelData: any;
	channelValue: any;
	retailerData: any;
	retailerValue: any;
	businessEnvironmentData: any;
	businessEnvironmentValue: any;
	similarToExistingData: any;
	similarToExistingValue: any;
	refProjectRequired: any;
	salesLocationData: any;
	salesLocationValue: any;
	designLocationData: any;
	designLocationValue: any;
	mfgLocationData: any;
	mfgLocationValue: any;
	assemblyLocationData: any;
	assemblyLocationValue: any;
	aeData: any;
	aeValue: any;
	brmData: any;
	brmValue: any;
	spmData: any;
	spmValue: any;
	ppmData: any;
	ppmValue: any;
	apcData: any;
	apcValue: any;
	dmData: any;
	dmValue: any;
	sdData: any;
	sdValue: any;
	gdValue: any;
	tm1Data: any;
	tm1Value: any;
	tm2Data: any;
	tm2Value: any;
	tm3Data: any;
	tm3Value: any;
	tm4Data: any;
	tm4Value: any;
	gdData: any;
	displayTypeData: any;
	displayTypeValue: any;
	apiError?: JSX.Element;
	showNotification?: JSX.Element;
	notificationType?:
		| 'error'
		| 'none'
		| 'success'
		| 'warning'
		| 'info'
		| undefined;
	notificationMessage: any;
	loading?: JSX.Element;
	projectId?: string;
	name?: string;
	taskIds?: string[] | null;
	userId?: string;
	createdAt?: string;
	updatedAt?: string;
	disableDesignTBD: boolean;
	showSkeleton: boolean;
	showForm: boolean;
}

const initialState: Project = {
	customer: [],
	billto: [],
	businessUnit: [],
	brand: [],
	projectTypeData: [],
	projectTypeValue: [],
	projectTemplateData: [],
	projectTemplateValue: [],
	channelData: [],
	channelValue: [],
	retailerData: [],
	retailerValue: [],
	businessEnvironmentData: [],
	businessEnvironmentValue: [],
	similarToExistingData: [],
	similarToExistingValue: [],
	refProjectRequired: [],
	salesLocationData: [],
	salesLocationValue: [],
	designLocationData: [],
	designLocationValue: [],
	mfgLocationData: [],
	mfgLocationValue: [],
	assemblyLocationData: [],
	assemblyLocationValue: [],
	aeData: [],
	aeValue: [],
	brmData: [],
	brmValue: [],
	spmData: [],
	spmValue: [],
	ppmData: [],
	ppmValue: [],
	apcData: [],
	apcValue: [],
	dmData: [],
	dmValue: [],
	sdData: [],
	sdValue: [],
	gdData: [],
	gdValue: [],
	tm1Data: [],
	tm1Value: [],
	tm2Data: [],
	tm2Value: [],
	tm3Data: [],
	tm3Value: [],
	tm4Data: [],
	tm4Value: [],
	displayTypeData: [],
	displayTypeValue: [],
	notificationMessage: [],
	disableDesignTBD: false,
	showSkeleton: true,
	showForm: false,
};

// project state slice
const projects = createSlice({
	name: 'projects', // name the slice
	initialState,
	// define all reducers
	reducers: {
		setCustomers(state, { payload }: PayloadAction<Customer[]>) {
			state.customer.push(...payload);
		},
		setCustomerBillTos(state, { payload }: PayloadAction<Customer[]>) {
			state.billto = payload;
		},
		setBusinessUnit(state, { payload }: PayloadAction<Customer[]>) {
			state.businessUnit = payload;
		},
		setBrand(state, { payload }: PayloadAction<Customer[]>) {
			state.brand = payload;
		},
		setProjectType(state, { payload }: PayloadAction<any>) {
			state.projectTypeData.push(...payload);
		},
		setProjectTemplate(state, { payload }: PayloadAction<any>) {
			state.projectTemplateValue = '';
			state.projectTemplateData = payload;
		},
		setChannel(state, { payload }: PayloadAction<any>) {
			state.channelData.push(...payload);
		},
		setRetailer(state, { payload }: PayloadAction<any>) {
			state.retailerData.push(...payload);
		},
		setBusEnv(state, { payload }: PayloadAction<any>) {
			state.businessEnvironmentData.push(...payload);
		},
		setSimilarToExisting(state, { payload }: PayloadAction<any>) {
			state.similarToExistingData.push(...payload);
		},
		setSalesLocation(state, { payload }: PayloadAction<any>) {
			state.salesLocationData.push(...payload);
		},
		setAssemblyLocation(state, { payload }: PayloadAction<any>) {
			state.assemblyLocationData.push(...payload);
		},
		setDesignLocation(state, { payload }: PayloadAction<any>) {
			state.designLocationData.push(...payload);
		},
		setMfgLocation(state, { payload }: PayloadAction<any>) {
			state.mfgLocationData.push(...payload);
		},
		setDisplayType(state, { payload }: PayloadAction<any>) {
			state.displayTypeData.push(...payload);
		},
		setAE(state, { payload }: PayloadAction<any>) {
			state.aeData.push(...payload);
		},
		setBRM(state, { payload }: PayloadAction<any>) {
			state.brmData.push(...payload);
		},
		setSPM(state, { payload }: PayloadAction<any>) {
			state.spmData.push(...payload);
		},
		setPPM(state, { payload }: PayloadAction<any>) {
			state.ppmData.push(...payload);
		},
		setAPC(state, { payload }: PayloadAction<any>) {
			state.apcData.push(...payload);
		},
		setDM(state, { payload }: PayloadAction<any>) {
			state.dmData.push(...payload);
		},
		setRefProjectRequired(state, { payload }: PayloadAction<any>) {
			state.refProjectRequired = payload;
		},
		showNotification(state, { payload }: PayloadAction<any>) {
			if (payload.showNotification) {
				state.showNotification = payload.showNotification;
			}
			if (payload.notificationMessage) {
				state.notificationMessage = payload.notificationMessage;
			}
			if (payload.notificationType) {
				state.notificationType = payload.notificationType;
			}
			if (payload.loading !== undefined) {
				state.loading = payload.loading;
			}
		},
		setLoading(state, { payload }: PayloadAction<any>) {
			state.loading = payload;
		},
		setAllUsers(state, { payload }: PayloadAction<any>) {
			state.sdData.push(...payload);
			state.gdData.push(...payload);
			state.tm1Data.push(...payload);
			state.tm2Data.push(...payload);
			state.tm3Data.push(...payload);
			state.tm4Data.push(...payload);
		},
		setDisableDesignTBD(state, { payload }: PayloadAction<any>) {
			state.disableDesignTBD = payload;
		},
		setInitialUI(state) {
			state.showForm = true;
			state.showSkeleton = false;
		},
	},
});

// define action creators and export them
export const {
	setCustomers,
	setCustomerBillTos,
	setBusinessUnit,
	setBrand,
	setProjectType,
	setProjectTemplate,
	setChannel,
	setRetailer,
	setBusEnv,
	setSimilarToExisting,
	setSalesLocation,
	setAssemblyLocation,
	setDesignLocation,
	setMfgLocation,
	setDisplayType,
	setAE,
	setBRM,
	setSPM,
	setPPM,
	setAPC,
	setDM,
	setRefProjectRequired,
	showNotification,
	setLoading,
	setAllUsers,
	setDisableDesignTBD,
	setInitialUI,
} = projects.actions;

// export reducers
export default projects.reducer;
