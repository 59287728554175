// import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import { APP_BAR_MOBILE_HEIGHT } from '../../theme/constants';

// import GlobalSideBar from '../../components/GlobalSideBar';
// import GlobalAppBar from '../../components/GlobalAppBar';
// import sidebarConfig from '../../globalSideBarConfig';

const RootStyle = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	minHeight: '100%',
	overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
	background: theme.palette.background.neutral,
	flexGrow: 1,
	overflow: 'auto',
	minHeight: '100%',
	paddingTop: APP_BAR_MOBILE_HEIGHT + 24,
	paddingBottom: theme.spacing(5),
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('lg')]: {
		paddingTop: theme.spacing(2),
	},
	backgroundColor: 'white',
}));

export default function DefaultLayout() {
	// const [open, setOpen] = useState(false);
	// const appName = 'app_name'; // maps to i18n translation string

	return (
		<RootStyle>
			{/* <GlobalAppBar onOpenSidebar={() => setOpen(true)} appName={appName} />
			<GlobalSideBar
				isOpenSidebar={open}
				onCloseSidebar={() => setOpen(false)}
				appName={appName}
				navItems={sidebarConfig}
			/> */}
			<MainStyle>
				<Outlet />
			</MainStyle>
		</RootStyle>
	);
}
