import { ProjectTask } from '../pages/task/task.interface';

const projectTasks: ProjectTask[] = [
	{
		name: 'Artwork Preflight',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'ArtworkPreflight',
		value: 'Artwork Preflight',
		TaskType: 'Artwork Preflight',
		fields: [
			{
				key: 'ArtworkPreflight_RequestedArtworkPreflight',
				label: 'Requested Artwork - Preflight',
				api_name: 'C_RequestedArtwork',
				required: true,
				shared: false,
				type: 'textarea',
				order: 8,
				max: 2000,
			},
			{
				key: 'ArtworkPreflight_DeliveryLocationPreflight',
				label: 'Delivery Location - Preflight',
				api_name: 'C_DeliveryLocation',
				required: true,
				shared: false,
				type: 'textfield',
				order: 9,
				max: 2000,
			},
		],
	},
	{
		name: 'Assembly Estimate',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'AssemblyEstimate',
		value: 'Assembly Estimate',
		TaskType: 'Assembly Estimate',
		fields: [],
	},
	{
		name: 'Concept Development',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'ConceptDevelopment',
		value: 'Concept Development',
		TaskType: 'Concept Development',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
			{
				key: 'GraphicDirection',
				label: 'Graphic Direction',
				api_name: 'C_GraphicDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 5,
				max: 2000,
			},
		],
	},
	{
		name: 'Customer Documents/Other',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'CustomerDocumentsOther',
		value: 'Customer Documents/Other',
		TaskType: 'Customer Documents/Other',
		fields: [
			{
				key: 'CustomerDocumentsOtherDirection',
				label: 'Customer Documents/Other Direction',
				api_name: 'C_CustomerDocumentsOtherDirection',
				required: true,
				shared: false,
				type: 'textarea',
				order: 16,
				max: 2000,
			},
		],
	},
	{
		name: 'Dielines',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'Dielines',
		value: 'Dielines',
		TaskType: 'Dielines',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
		],
		notes:
			'Warning: Completed structure required prior to requesting this task',
	},
	{
		name: 'Flat Artwork',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'FlatArtwork',
		value: 'Flat Artwork',
		TaskType: 'Flat Artwork',
		fields: [
			{
				key: 'GraphicDirection',
				label: 'Graphic Direction',
				api_name: 'C_GraphicDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 5,
				max: 2000,
			},
		],
	},
	{
		name: 'Label Artwork',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'Label Artwork',
		value: 'LabelArtwork',
		TaskType: 'Label Artwork',
		fields: [
			{
				key: 'SizeRequirementsLabelDirection',
				label: 'Size Requirements / Label Direction',
				api_name: 'C_SizeRequirements',
				required: true,
				shared: true,
				type: 'textarea',
				order: 6,
				max: 2000,
			},
		],
	},
	{
		name: 'Material Estimate',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'Material Estimate',
		value: 'MaterialEstimate',
		TaskType: 'Material Estimate',
		fields: [],
	},
	{
		name: 'Mockup',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'Mockup',
		value: 'Mockup',
		TaskType: 'Mockup',
		fields: [
			{
				key: 'QuantityMockup',
				label: 'Quantity - Mockup',
				api_name: 'C_Quantity',
				required: true,
				shared: false,
				type: 'number',
				order: 12,
			},
			{
				key: 'MockupDirection',
				label: 'Mockup Direction/Shipping Info',
				api_name: 'C_MockupDirection',
				required: true,
				shared: false,
				type: 'textarea',
				max: 2000,
				order: 13,
			},
		],
	},
	{
		name: 'PAI',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'PAI',
		value: 'PAI',
		TaskType: 'PAI',
		fields: [],
		notes:
			'Warning: Completed structure required prior to requesting this task',
	},
	{
		name: 'Pallet Pattern',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'PalletPattern',
		value: 'Pallet Pattern',
		TaskType: 'Pallet Pattern',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
		],
		notes:
			'Warning: Completed structure required prior to requesting this task',
	},
	{
		name: 'Planogram',
		classification: '',
		duedate: null,
		billable: false,
		resource: '',
		files: [],
		key: 'Planogram',
		value: 'Planogram',
		TaskType: 'Planogram',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
			{
				key: 'ProductQty',
				label: 'Product Qty',
				api_name: 'C_ProductQty',
				required: true,
				shared: true,
				type: 'number',
				order: 2,
			},
			{
				key: 'ProductPOGDirection',
				label: 'Product/POG Direction',
				api_name: 'C_ProductInfo',
				required: true,
				shared: true,
				type: 'textarea',
				order: 4,
				max: 2000,
			},
		],
	},
	{
		name: 'Prototype - Permanent',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'ProtoPermanent',
		value: 'Prototype - Permanent',
		TaskType: 'Prototype - Permanent',
		fields: [
			{
				key: 'QuantityPrototype',
				label: 'Quantity - Prototype',
				api_name: 'C_Quantity',
				required: true,
				shared: false,
				type: 'number',
				order: 14,
			},
			{
				key: 'PrototypeDirection',
				label: 'Prototype Direction',
				api_name: 'C_PrototypeDirection',
				required: true,
				shared: false,
				type: 'textarea',
				order: 15,
				max: 2000,
			},
		],
	},
	{
		name: 'RAI',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'RAI',
		value: 'RAI',
		TaskType: 'RAI',
		fields: [],
		notes:
			'Warning: Completed structure required prior to requesting this task',
	},
	{
		name: 'Render',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'Render',
		value: 'Render',
		TaskType: 'Render',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
			{
				key: 'ProductQty',
				label: 'Product Qty',
				api_name: 'C_ProductQty',
				required: true,
				shared: true,
				type: 'number',
				order: 2,
			},
			{
				key: 'ProductPOGDirection',
				label: 'Product/POG Direction',
				api_name: 'C_ProductInfo',
				required: true,
				shared: true,
				type: 'textarea',
				order: 4,
				max: 2000,
			},
			{
				key: 'GraphicDirection',
				label: 'Graphic Direction',
				api_name: 'C_GraphicDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 5,
				max: 2000,
			},
		],
	},
	{
		name: 'Sales Estimate Request',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'SalesEstimateRequest',
		value: 'Sales Estimate Request',
		TaskType: 'Sales Estimate Request',
		fields: [
			{
				key: 'EstimateType',
				label: 'Estimate Type',
				api_name: 'C_EstimateType',
				required: true,
				type: 'dropdown',
				shared: true,
				order: 17,
				values: [
					{
						id: 'Material',
						text: 'Material',
					},
					{
						id: 'Assembly',
						text: 'Assembly',
					},
					{
						id: 'Material and Assembly',
						text: 'Material and Assembly',
					},
				],
			},
			{
				key: 'RequestedQuantities',
				label: 'Requested Quantities',
				api_name: 'C_WorkItemRequestedQuantity',
				required: true,
				shared: true,
				type: 'textarea',
				order: 18,
				max: 2000,
			},
			{
				key: 'EstimateDirection',
				label: 'Estimate Direction',
				api_name: 'C_EstimateDirection',
				required: false,
				shared: true,
				type: 'textarea',
				order: 19,
				max: 2000,
			},
		],
	},
	{
		name: 'Ship Test',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'ShipTest',
		value: 'Ship Test',
		TaskType: 'Ship Test',
		fields: [
			{
				key: 'ProductQty',
				label: 'Product Qty',
				api_name: 'C_ProductQty',
				required: true,
				shared: true,
				type: 'number',
				order: 2,
			},
			{
				key: 'ProductPOGDirection',
				label: 'Product/POG Direction',
				api_name: 'C_ProductInfo',
				required: true,
				shared: true,
				type: 'textarea',
				order: 4,
				max: 2000,
			},
			{
				key: 'ProductLocationShipTestDirection',
				label: 'Product Location/Ship Test Direction',
				api_name: 'C_ProductLocation',
				required: false,
				shared: false,
				type: 'textarea',
				order: 7,
				max: 2000,
			},
		],
	},
	{
		name: 'Specs',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'Specs',
		value: 'Specs',
		TaskType: 'Specs',
		fields: [
			{
				key: 'StructureDirection',
				label: 'Structure Direction',
				api_name: 'C_StructureDirection',
				required: true,
				shared: true,
				type: 'textarea',
				order: 1,
				max: 2000,
			},
			{
				key: 'ProductQty',
				label: 'Product Qty',
				api_name: 'C_ProductQty',
				required: true,
				shared: true,
				type: 'number',
				order: 2,
			},
			{
				key: 'ShippingWithProduct',
				label: 'Shipping with product?',
				api_name: 'C_ShippingWithProduct',
				required: true,
				type: 'dropdown',
				shared: true,
				// "values": [
				//     "Pre-pack",
				//     "Nearpack",
				//     "None"
				// ],
				order: 3,
			},
			{
				key: 'ProductPOGDirection',
				label: 'Product/POG Direction',
				api_name: 'C_ProductInfo',
				required: true,
				shared: true,
				type: 'textarea',
				order: 4,
				max: 2000,
			},
		],
	},
	{
		name: 'White Sample',
		classification: '',
		duedate: null,
		resource: '',
		billable: false,
		files: [],
		key: 'WhiteSample',
		value: 'White Sample',
		TaskType: 'White Sample',
		fields: [
			{
				key: 'QuantityWhiteSample',
				label: 'Quantity - White Sample',
				api_name: 'C_Quantity',
				required: true,
				shared: false,
				type: 'number',
				order: 10,
			},
			{
				key: 'WhiteSampleDirection',
				label: 'White Sample Direction/Shipping Info',
				api_name: 'C_WhiteSampleDirection',
				required: true,
				shared: false,
				type: 'textarea',
				order: 11,
				max: 2000,
			},
		],
	},
];

export default projectTasks;
