import { UserManager } from 'oidc-client';
import { storeUser, removeUser } from './authSlice';

const config = {
	authority: process.env.REACT_APP_SOTER_AUTHORITY,
	client_id: process.env.REACT_APP_SOTER_CLIENT_ID,
	redirect_uri: process.env.REACT_APP_SOTER_REDIRECT_URI,
	response_type: 'code',
	scope: 'openid profile',
	loadUserInfo: true,
	automaticSilentRenew: true,
	silent_redirect_uri: process.env.REACT_APP_SOTER_SILENT_RENEW_REDIRECT_URI,
};

const userManager = new UserManager(config);

export const loadUserFromStorage = async (dispatch) => {
	try {
		const user = await userManager.getUser();
		if (user) {
			dispatch(storeUser(user.toStorageString()));
		} else {
			dispatch(removeUser());
		}
	} catch (e) {
		dispatch(removeUser());
	}

	return null;
};

export function signinRedirect() {
	// eslint-disable-next-line no-restricted-globals
	return userManager.signinRedirect({ state: location.search });
}

export function signinRedirectCallback() {
	return userManager.signinRedirectCallback();
}

export function signinSilentCallback() {
	return userManager.signinSilentCallback();
}

export default userManager;
