import * as React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import {
	Input,
	MaskedTextBox,
	NumericTextBox,
	Checkbox,
	ColorPicker,
	Switch,
	RadioGroup,
	Slider,
	SliderLabel,
	TextArea,
} from '@progress/kendo-react-inputs';
import {
	DatePicker,
	TimePicker,
	DateTimePicker,
	DateRangePicker,
	DateInput,
	Calendar,
} from '@progress/kendo-react-dateinputs';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { Upload } from '@progress/kendo-react-upload';
import {
	DropDownList,
	AutoComplete,
	MultiSelect,
	ComboBox,
} from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { FloatingLabel } from './FloatingLabel';

export const FormInput = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		value,
		max,
		key,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<FloatingLabel
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}
			>
				{/* <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label> */}

				<Input
					key={key}
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					value={value}
					validationMessage={validationMessage}
					{...others}
				/>
			</FloatingLabel>
			<span className="k-form-hint" style={{ float: 'right' }}>
				{(!value ? '' : `${value}`).length} / {max}
			</span>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormRadioGroup = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		label,
		valid,
		disabled,
		hint,
		visited,
		modified,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<RadioGroup
				id={id}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ariaLabelledBy={labelId}
				valid={valid}
				disabled={disabled}
				ref={editorRef}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormNumericTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		style,
		min,
		optional,
		value,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<FloatingLabel
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}
			>
				{/* <Label editorId={id} editorValid={valid} editorDisabled={disabled}>{label}</Label> */}
				<NumericTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					style={style}
					min={min}
					value={value}
					validationMessage={' '}
					{...others}
				/>
			</FloatingLabel>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormCheckbox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		id,
		valid,
		disabled,
		hint,
		optional,
		label,
		visited,
		modified,
		style,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<Checkbox
				ariaDescribedBy={`${hintId} ${errorId}`}
				label={label}
				labelOptional={optional}
				valid={valid}
				id={id}
				disabled={disabled}
				style={style}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormSwitch = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		optional,
		id,
		valid,
		disabled,
		hint,
		checked,
		value,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
			>
				{label}
			</Label>
			<Switch
				ref={editorRef}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				checked={value}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormMaskedTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		optional,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<Label editorId={id} editorValid={valid} optional={optional}>
				{label}
			</Label>
			<div className="k-form-field-wrap">
				<MaskedTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormTextArea = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		hint,
		disabled,
		optional,
		value,
		max,
		key,
		rows,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			{/* <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label> */}
			<FloatingLabel
				key={`label${key}`}
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}
			>
				<TextArea
					key={key}
					valid={valid}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					rows={rows ?? 5}
					maxLength={max}
					value={value}
					validationMessage={' '}
					{...others}
				/>
			</FloatingLabel>
			<span className="k-form-hint" style={{ float: 'right' }}>
				{(!value ? '' : `${value}`).length} / {max}
			</span>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormColorPicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<ColorPicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormSlider = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		data,
		...others
	} = fieldRenderProps;

	const editorRef = React.useRef(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<Slider
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			>
				{data.map((value) => (
					<SliderLabel title={value} key={value} position={value} />
				))}
			</Slider>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormUpload = (fieldRenderProps) => {
	const {
		valid,
		value,
		id,
		optional,
		label,
		hint,
		validationMessage,
		touched,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	const onChangeHandler = (event) => {
		fieldRenderProps.onChange({ value: event.newState });
	};
	const onRemoveHandler = (event) => {
		fieldRenderProps.onChange({ value: event.newState });
	};

	return (
		<FieldWrapper>
			<Label id={labelId} editorId={id} editorValid={valid} optional={optional}>
				{label}
			</Label>
			<Upload
				id={id}
				valid={valid}
				autoUpload={false}
				showActionButtons={false}
				multiple
				files={value}
				onAdd={onChangeHandler}
				onRemove={onRemoveHandler}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ariaLabelledBy={labelId}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormDropDownList = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<DropDownList
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				validationMessage={' '}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormAutoComplete = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<AutoComplete
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormComboBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		optional,
		onChange,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			{/* <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                label={label}>
                {label}
            </Label> */}
			<ComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				label={label + (optional ? '' : ' *')}
				onChange={onChange}
				validationMessage={' '}
				filterable
				popupSettings={{ width: 'auto' }}
				{...others}
			/>

			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormComboBoxFilter = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		optional,
		onChange,
		data,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';
	const initdata = data.slice();
	const [statedata, setdata] = React.useState(data.slice());

	return (
		<FieldWrapper style={wrapperStyle}>
			{/* <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                label={label}>
                {label}
            </Label> */}
			<ComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				label={label}
				onChange={onChange}
				onFilterChange={(e) => {
					const ldata = initdata.slice();
					setdata(filterBy(ldata, e.filter));
				}}
				filterable
				data={statedata}
				validationMessage={' '}
				{...others}
			/>

			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
export const CustomerComboBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		optional,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			{/* <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                label={label}>
                {label}
            </Label> */}
			<ComboBox
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				label={`${label} *`}
				// eslint-disable-next-line react/destructuring-assignment
				onChange={fieldRenderProps.onCustomerChange}
				validationMessage={' '}
				{...others}
			/>

			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormMultiSelect = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			{/* <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
                {label}
            </Label> */}
			<MultiSelect
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				label={label}
				autoClose={false}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormDatePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		hintDirection,
		optional,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label + (optional ? '' : ' *')}
			</Label>
			<div className="k-form-field-wrap">
				<DatePicker
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					disabled={disabled}
					calendar={Calendar}
					{...others}
				/>
				{showHint && (
					<Hint id={hintId} direction={hintDirection}>
						{hint}
					</Hint>
				)}
				{showValidationMessage && (
					<Error id={errorId}>{validationMessage}</Error>
				)}
			</div>
		</FieldWrapper>
	);
};

export const FormDateTimePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<DateTimePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormTimePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<TimePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormDateInput = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<DateInput
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				disabled={disabled}
				validationMessage={' '}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormDateRangePicker = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		wrapperStyle,
		...others
	} = fieldRenderProps;
	const editorRef = React.useRef(null);

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';
	const labelId = label ? `${id}_label` : '';

	return (
		<FieldWrapper style={wrapperStyle}>
			<Label
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label}
			</Label>
			<DateRangePicker
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormFloatingInput = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		type,
		optional,
		value,
		max,
		key,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<FloatingLabel
				key={`label${key}`}
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}
			>
				<Input
					key={key}
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					maxLength={max}
					value={value}
					validationMessage={' '}
					{...others}
				/>
			</FloatingLabel>
			<span className="k-form-hint" style={{ float: 'right' }}>
				{(!value ? '' : `${value}`).length} / {max}
			</span>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

export const FormFloatingNumericTextBox = (fieldRenderProps) => {
	const {
		validationMessage,
		touched,
		label,
		id,
		valid,
		disabled,
		hint,
		optional,
		value,
		...others
	} = fieldRenderProps;

	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : '';
	const errorId = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper>
			<FloatingLabel
				optional={optional}
				editorValue={value}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				label={label}
			>
				<NumericTextBox
					ariaDescribedBy={`${hintId} ${errorId}`}
					value={value}
					valid={valid}
					id={id}
					disabled={disabled}
					format={{
						style: 'decimal',
						useGrouping: false,
						minimumFractionDigits: 0,
					}}
					{...others}
				/>
			</FloatingLabel>

			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};

const kendoHOC = (
	type,
	{ input: { onChange, onBlur, value, ...restInput }, meta, label, ...rest }
) => {
	const Type = type;

	return (
		<span className="k-form-field">
			<span>{label}</span>
			<Type
				{...restInput}
				{...rest}
				checked={value}
				value={value}
				onChange={(event) => {
					onChange(event.value || event.target.value);
				}}
				onBlur={(event) => onBlur(event.value)}
			/>
			{meta.error && meta.touched && (
				<span className="k-required">{meta.error}</span>
			)}
		</span>
	);
};

const KendoInput = (options) => kendoHOC(Input, options);
const KendoNumericTextBox = (options) => kendoHOC(NumericTextBox, options);
const KendoDatePicker = (options) => kendoHOC(DatePicker, options);
const KendoDropDown = (options) => kendoHOC(DropDownList, options);
const KendoSwitch = (options) => kendoHOC(Switch, options);

export {
	KendoInput,
	KendoNumericTextBox,
	KendoDatePicker,
	KendoDropDown,
	KendoSwitch,
};
