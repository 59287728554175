import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import {
	Notification,
	NotificationGroup,
} from '@progress/kendo-react-notification';
import { Fade, Push } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import { filterBy } from '@progress/kendo-data-query';
import '@progress/kendo-theme-material/dist/all.css';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Card, CardFooter, CardHeader } from '@progress/kendo-react-layout';
import { ZonedDate } from '@progress/kendo-date-math';
import { requiredValidator } from '../../components/validators';
import { RootState } from '../../store';
import * as projectReducers from './projectSlice';
import * as api from '../../services/api';
import {
	FormTextArea,
	FormDatePicker,
	FormComboBox,
	CustomerComboBox,
	FormFloatingInput,
	FormMultiSelect,
	FormFloatingNumericTextBox,
} from '../../components/form-components';
import * as authReducer from '../../authorization/authorizationSlice';

interface Props {
	id?: string;
}

const ProjectDetails: FC<Props> = () => {
	const project = useSelector((state: RootState) => state.project);
	const navigate = useNavigate();
	const [customerData, setCustomerData] = React.useState(
		project.customer.slice()
	);
	const [customerBillToData, setCustomerBillToData] = React.useState(
		project.billto.slice()
	);
	const [businessUnitData, setBusinessUnitData] = React.useState(
		project.businessUnit.slice()
	);
	const [brandData, setBrandData] = React.useState(project.brand.slice());
	const [projectTemplateData, setProjectTemplateData] = React.useState(
		project.projectTemplateData.slice()
	);
	const [channelData, setChannelData] = React.useState(
		project.projectTypeData.slice()
	);
	const [retailerData, setRetailerData] = React.useState(
		project.projectTypeData.slice()
	);
	const [businessEnvironmentData, setBusEnvData] = React.useState(
		project.projectTypeData.slice()
	);
	const [similarToExistingData, setSEPData] = React.useState(
		project.projectTypeData.slice()
	);
	const [salesLocationData, setSLData] = React.useState(
		project.projectTypeData.slice()
	);
	const [designLocationData, setDLData] = React.useState(
		project.projectTypeData.slice()
	);
	const [mfgLocationData, setMfgData] = React.useState(
		project.projectTypeData.slice()
	);
	const [assemblyLocationData, setAssemblyData] = React.useState(
		project.projectTypeData.slice()
	);
	const [aeData, setAEData] = React.useState(project.projectTypeData.slice());
	const [brmData, setBRMData] = React.useState(project.projectTypeData.slice());
	const [spmData, setSPMData] = React.useState(project.projectTypeData.slice());
	const [dmData, setDMData] = React.useState(project.projectTypeData.slice());
	const [ppmData, setPPMData] = React.useState(project.projectTypeData.slice());
	const [apcData, setAPCData] = React.useState(project.projectTypeData.slice());
	const [sdData, setSDData] = React.useState(project.projectTypeData.slice());
	const [gdData, setGDData] = React.useState(project.projectTypeData.slice());
	const [tm1Data, setTM1Data] = React.useState(project.projectTypeData.slice());
	const [tm2Data, setTM2Data] = React.useState(project.projectTypeData.slice());
	const [tm3Data, setTM3Data] = React.useState(project.projectTypeData.slice());
	const [tm4Data, setTM4Data] = React.useState(project.projectTypeData.slice());
	const [displayTypeData, setDisplayData] = React.useState(
		project.projectTypeData.slice()
	);
	const [ptData, setptData] = React.useState(project.projectTypeData.slice());
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		const clzsid = new URLSearchParams(location.search).get('clzsid');
		dispatch(authReducer.setclzsid(clzsid));

		const fetchCustomers = async () => {
			api.http
				.post<any, any[]>(api.urlQuery, { q: api.getCustomersQuery })
				.then((response) => {
					dispatch(
						projectReducers.setCustomers(
							api.parseCustomerQueryResponse(response)
						)
					);
					setCustomerData(api.parseCustomerQueryResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getProjectType = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=ProjectType`)
				.then((response) => {
					dispatch(
						projectReducers.setProjectType(api.parsePickListResponse(response))
					);
					setptData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		const getTemplates = async () => {
			api.http
				.post(api.urlQuery, { q: 'SELECT Name FROM Template LIMIT 5000' })
				.then((response) => {
					dispatch(
						projectReducers.setProjectTemplate(
							api.parseCustomerQueryResponse(response)
						)
					);
					setProjectTemplateData(api.parseCustomerQueryResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		const getChannel = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectChannel`)
				.then((response) => {
					dispatch(
						projectReducers.setChannel(api.parsePickListResponse(response))
					);
					setChannelData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getRetailer = async () => {
			api.http
				.post(api.urlQuery, {
					q: api.getRetailersQuery,
				})
				.then((response) => {
					dispatch(
						projectReducers.setRetailer(
							api.parseCustomerQueryResponse(response)
						)
					);
					setRetailerData(api.parseCustomerQueryResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getBusEnv = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectBusEnvironment`)
				.then((response) => {
					dispatch(
						projectReducers.setBusEnv(api.parsePickListResponse(response))
					);
					setBusEnvData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getSimilarToExisting = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectSimilartoExistingProject`)
				.then((response) => {
					dispatch(
						projectReducers.setSimilarToExisting(
							api.parsePickListResponse(response)
						)
					);
					setSEPData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		const getSalesLocation = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectSalesLocation`)
				.then((response) => {
					dispatch(
						projectReducers.setSalesLocation(
							api.parsePickListResponse(response)
						)
					);
					setSLData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		const getAssemblyLocation = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_WorkItemAssyLocation`)
				.then((response) => {
					dispatch(
						projectReducers.setAssemblyLocation(
							api.parsePickListResponse(response)
						)
					);
					setAssemblyData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getDesignLocation = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectDesignLocation`)
				.then((response) => {
					dispatch(
						projectReducers.setDesignLocation(
							api.parsePickListResponse(response)
						)
					);
					setDLData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		const getMfgLocation = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectMfgLocation`)
				.then((response) => {
					dispatch(
						projectReducers.setMfgLocation(api.parsePickListResponse(response))
					);
					setMfgData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		const getDisplayType = async () => {
			api.http
				.get(`${api.urlPickList}?typeNames=C_ProjectDisplayType`)
				.then((response) => {
					dispatch(
						projectReducers.setDisplayType(api.parsePickListResponse(response))
					);
					setDisplayData(api.parsePickListResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};
		// const getAE = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='Account Executive') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };
		// const getBRM = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='BRM') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };
		// const getSPM = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='SPM') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };
		// const getPPM = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='PPM') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };
		// const getAPC = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='APC') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };
		// const getDM = async () => {
		// 	api.http
		// 		.post(api.urlQuery, {
		// 			q: "Select Member.Name from SkillLink  where Container in (Select id from Skill where Name='Design Manager') LIMIT 5000",
		// 		})
		// 		.then((response) => {
		// 		})
		// 		.catch((error) => {
		// 			dispatch(
		// 				projectReducers.showNotification({
		// 					showNotification: true,
		// 					notificationMessage: error,
		// 					notificationType: 'error',
		// 					loading: false,
		// 				})
		// 			);
		// 		});
		// };

		const getAllUsers = async () => {
			api.http
				.post(api.urlQuery, {
					q: 'Select Name from User WHERE ExternalUser = false ORDER BY Name LIMIT 5000',
				})
				.then((response) => {
					dispatch(
						projectReducers.setAE(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setBRM(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setSPM(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setPPM(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setAPC(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setDM(api.parseCustomerQueryResponse(response))
					);
					dispatch(
						projectReducers.setAllUsers(
							api.parseCustomerQueryResponse(response)
						)
					);
					setAEData(api.parseCustomerQueryResponse(response));
					setBRMData(api.parseCustomerQueryResponse(response));
					setSPMData(api.parseCustomerQueryResponse(response));
					setPPMData(api.parseCustomerQueryResponse(response));
					setAPCData(api.parseCustomerQueryResponse(response));
					setDMData(api.parseCustomerQueryResponse(response));
					setSDData(api.parseCustomerQueryResponse(response));
					setGDData(api.parseCustomerQueryResponse(response));
					setTM1Data(api.parseCustomerQueryResponse(response));
					setTM2Data(api.parseCustomerQueryResponse(response));
					setTM3Data(api.parseCustomerQueryResponse(response));
					setTM4Data(api.parseCustomerQueryResponse(response));
				})
				.catch((error) => {
					dispatch(
						projectReducers.showNotification({
							showNotification: true,
							notificationMessage: error,
							notificationType: 'error',
							loading: false,
						})
					);
				});
		};

		fetchCustomers();
		getProjectType();
		getTemplates();
		getChannel();
		getRetailer();
		getBusEnv();
		getSimilarToExisting();
		getSalesLocation();
		getAssemblyLocation();
		getDesignLocation();
		getMfgLocation();
		getDisplayType();
		// getAE();
		// getBRM();
		// getSPM();
		// getPPM();
		// getAPC();
		// getDM();
		getAllUsers();
		dispatch(projectReducers.setInitialUI());
	}, [dispatch, location.search]);

	const onCustomerChange = (event: ComboBoxChangeEvent) => {
		const { value } = event.target;
		api.http
			.post(api.urlQuery, {
				q: `Select Name from C_LinkedInformation  where C_ItemType = 'Customer BillTo' AND C_CustomerCatalog = '${
					value && value.id ? value.id : ''
				}'  LIMIT 5000`,
			})
			.then((response) => {
				dispatch(
					projectReducers.setCustomerBillTos(
						api.parseCustomerQueryResponse(response)
					)
				);
				setCustomerBillToData(api.parseCustomerQueryResponse(response));
			})
			.catch((error) => {
				dispatch(
					projectReducers.showNotification({
						showNotification: true,
						notificationMessage: error,
						notificationType: 'error',
						loading: false,
					})
				);
			});

		api.http
			.post(api.urlQuery, {
				q: `Select Name from C_LinkedInformation  where C_ItemType = 'Business Unit' AND C_CustomerCatalog = '${
					value && value.id ? value.id : ''
				}' LIMIT 5000`,
			})
			.then((response) => {
				dispatch(
					projectReducers.setBusinessUnit(
						api.parseCustomerQueryResponse(response)
					)
				);
				setBusinessUnitData(api.parseCustomerQueryResponse(response));
			})
			.catch((error) => {
				dispatch(
					projectReducers.showNotification({
						showNotification: true,
						notificationMessage: error,
						notificationType: 'error',
						loading: false,
					})
				);
			});

		api.http
			.post(api.urlQuery, {
				q: `Select Name from C_LinkedInformation  where C_ItemType = 'Brand' AND C_CustomerCatalog = '${
					value && value.id ? value.id : ''
				}' LIMIT 5000`,
			})
			.then((response) => {
				dispatch(
					projectReducers.setBrand(api.parseCustomerQueryResponse(response))
				);
				setBrandData(api.parseCustomerQueryResponse(response));
			})
			.catch((error) => {
				dispatch(
					projectReducers.showNotification({
						showNotification: true,
						notificationMessage: error,
						notificationType: 'error',
						loading: false,
					})
				);
			});
	};

	const handleSubmitClick = () => {
		setTimeout(() => {
			const invalidElement =
				document.querySelector<Element>('.k-state-invalid');
			if (invalidElement) {
				invalidElement.getElementsByTagName('input')[0].focus();
			}
		}, 10);
	};

	const getid = (prop: any) => (prop && prop.id ? prop.id : '');

	const handleSubmit = (entity: any) => {
		dispatch(projectReducers.setLoading(true));
		try {
			const projectObject = {
				C_Customer: getid(entity.C_Customer),
				C_CustomerBillTo: getid(entity.C_CustomerBillTo),
				Name: entity.Name,
				Description: entity.Description,
				ProjectType: getid(entity.ProjectType),
				C_Channel:
					entity.C_ProjectChannel &&
					entity.C_ProjectChannel.map((i: any) => i.id).join(';'),
				C_Retailer: getid(entity.C_Retailer),
				C_BusinessUnit: getid(entity.C_BusinessUnit),
				C_Brand: getid(entity.C_Brand),
				C_BusEnvironment: getid(entity.C_ProjectBusEnvironment),
				C_ApproximateBudget: entity.C_ApproximateBudget,
				C_SimilartoExistingProject: getid(
					entity.C_ProjectSimilartoExistingProject
				),
				C_RefProject: entity.C_RefProject,
				C_ScheduleKey: ZonedDate.fromUTCDate(
					entity.C_ScheduleKey,
					'America/New_York'
				),
				C_SalesLocation: getid(entity.C_ProjectSalesLocation),
				C_DesignLocation: getid(entity.C_ProjectDesignLocation),
				C_MfgLocation: getid(entity.C_ProjectMfgLocation),
				C_AssyLocation: getid(entity.C_WorkItemAssyLocation),
				C_FOBCityandState: entity.C_FOBCityandState,
				C_AccountExecutive: getid(entity.C_AccountExecutive),
				C_BRM: getid(entity.C_BRM),
				C_DM: getid(entity.C_DM),
				C_SPM: getid(entity.C_SPM),
				C_PPM: getid(entity.C_PPM),
				C_APC: getid(entity.C_APC),
				C_StructuralDesigner: getid(entity.C_StructuralDesigner),
				C_GraphicDesigner: getid(entity.C_GraphicDesigner),
				C_TeamMember1: getid(entity.C_TeamMember1),
				C_TeamMember2: getid(entity.C_TeamMember2),
				C_TeamMember3: getid(entity.C_TeamMember3),
				C_TeamMember4: getid(entity.C_TeamMember4),
				C_DisplayType: getid(entity.C_ProjectDisplayType),
				C_CustomerItemNum: entity.C_CustomerItemNum,
				SKU: entity.SKU,
				C_AssemblyOrderQty: entity.C_AssemblyOrderQty,
				C_MaterialOrderQty: entity.C_MaterialOrderQty,
				C_IsTemplate: '/C_ProjectIsTemplate/No',
				C_Template: entity.Template.text,
			};
			if (project.projectId == null || project.projectId === '') {
				Object.assign(projectObject, { Id: '/Project' });
			}
			if (project.projectId == null || project.projectId === '') {
				api.http
					.post(api.urlCreateFromTemplate, {
						TemplateName: entity.Template.text,
						entity: projectObject,
						fields: ['Name'],
					})
					.then((response: any) => {
						dispatch(
							projectReducers.showNotification({
								showNotification: true,
								notificationMessage: 'Project created successfully',
								notificationType: 'success',
								loading: false,
							})
						);
						navigate(
							`/task?projectid=${response.id}&clzsid=${new URLSearchParams(
								location.search
							).get('clzsid')}`
						);
					})
					.catch((error) => {
						dispatch(
							projectReducers.showNotification({
								showNotification: true,
								notificationMessage: error,
								notificationType: 'error',
								loading: false,
							})
						);
					});
			} else {
				api.http
					.post(api.urlObjects + project.projectId, projectObject)
					.then(() => {
						dispatch(
							projectReducers.showNotification({
								showNotification: true,
								notificationMessage: 'Project updated successfully',
								notificationType: 'success',
								loading: false,
							})
						);
					})
					.catch((error) => {
						dispatch(
							projectReducers.showNotification({
								showNotification: true,
								notificationMessage: error,
								notificationType: 'error',
								loading: false,
							})
						);
					});
			}
		} catch (ex) {
			dispatch(projectReducers.setLoading(false));
			dispatch(
				projectReducers.showNotification({
					showNotification: true,
					notificationMessage: ex,
					notificationType: 'error',
					loading: false,
				})
			);
		}
	};

	return (
		<div id="projectDetails">
			<div className="pageHeader">Project Form </div>
			{project.showSkeleton && (
				<div className="col-4">
					<Card>
						<CardHeader className="k-hbox">
							<Skeleton
								shape="circle"
								style={{ width: 45, height: 45, marginRight: 16 }}
								animation={{ type: 'wave' }}
							/>
							<div style={{ flex: '1 1 50%' }}>
								<Skeleton
									shape="text"
									style={{ width: '100%' }}
									animation={{ type: 'wave' }}
								/>
								<Skeleton
									shape="text"
									style={{ width: '40%' }}
									animation={{ type: 'wave' }}
								/>
							</div>
						</CardHeader>
						<Skeleton
							shape="rectangle"
							style={{ width: '100%', height: 150 }}
							animation={{ type: 'wave' }}
						/>
						<CardFooter>
							<Skeleton
								shape="text"
								style={{ width: '100%' }}
								animation={{ type: 'wave' }}
							/>
						</CardFooter>
					</Card>
				</div>
			)}
			{project.showForm && (
				<Form
					onSubmit={handleSubmit}
					onSubmitClick={handleSubmitClick}
					render={(formRenderProps) => (
						<FormElement className="projectForm">
							<fieldset className="k-form-fieldset">
								<legend className="k-form-legend">General Details</legend>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											data={customerData}
											dataItemKey="id"
											textField="text"
											id="C_Customer"
											name="C_Customer"
											label="Customer"
											component={CustomerComboBox}
											filterable
											validator={requiredValidator}
											onChange={(e: any) => {
												formRenderProps.onChange('C_CustomerBillTo', {
													value: '',
												});
												formRenderProps.onChange('C_BusinessUnit', {
													value: '',
												});
												formRenderProps.onChange('C_Brand', { value: '' });
												onCustomerChange(e);
											}}
											onFilterChange={(e: any) => {
												const data = project.customer.slice();
												setCustomerData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											data={customerBillToData}
											dataItemKey="id"
											textField="text"
											id="C_CustomerBillTo"
											name="C_CustomerBillTo"
											label="Customer Bill To"
											validator={requiredValidator}
											component={FormComboBox}
											filterable
											onFilterChange={(e: any) => {
												const data = project.billto.slice();
												setCustomerBillToData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '100%' }}>
										<Field
											id="Name"
											name="Name"
											label="Project Name"
											component={FormFloatingInput}
											validator={requiredValidator}
											max={100}
											value={formRenderProps.valueGetter('Name')}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '100%' }}>
										<Field
											id="Description"
											name="Description"
											label="Project Description"
											component={FormTextArea}
											optional
											max={2000}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="ProjectType"
											name="ProjectType"
											label="Project Type"
											component={FormComboBox}
											validator={requiredValidator}
											data={ptData}
											value={project.projectTypeValue}
											dataItemKey="id"
											textField="text"
											filterable
											onFilterChange={(e: any) => {
												const data = project.projectTypeData.slice();
												setptData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="Template"
											name="Template"
											label="Template"
											component={FormComboBox}
											data={projectTemplateData}
											value={project.projectTemplateValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											// optional={true}
											validator={requiredValidator}
											filterable
											onFilterChange={(e: any) => {
												const data = project.projectTemplateData.slice();
												setProjectTemplateData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectChannel"
											name="C_ProjectChannel"
											label="Channel"
											component={FormMultiSelect}
											data={channelData}
											value={project.channelValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.channelData.slice();
												setChannelData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_Retailer"
											name="C_Retailer"
											label="Retailer"
											component={FormComboBox}
											data={retailerData}
											value={project.retailerValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.retailerData.slice();
												setRetailerData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											data={businessUnitData}
											dataItemKey="id"
											textField="text"
											id="C_BusinessUnit"
											name="C_BusinessUnit"
											label="Business Unit"
											component={FormComboBox}
											optional
											onFilterChange={(e: any) => {
												const data = project.businessUnit.slice();
												setBusinessUnitData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											data={brandData}
											dataItemKey="id"
											textField="text"
											id="C_Brand"
											name="C_Brand"
											label="Brand"
											component={FormComboBox}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.brand.slice();
												setBrandData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>

								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectBusEnvironment"
											name="C_ProjectBusEnvironment"
											label="Business Environment"
											component={FormComboBox}
											validator={requiredValidator}
											data={businessEnvironmentData}
											value={project.businessEnvironmentValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.businessEnvironmentData.slice();
												setBusEnvData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_ApproximateBudget"
											name="C_ApproximateBudget"
											label="Approximate Budget"
											component={FormFloatingInput}
											// optional={true}
											validator={requiredValidator}
											max={50}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectSimilartoExistingProject"
											name="C_ProjectSimilartoExistingProject"
											label="Similar to Existing Project"
											component={FormComboBox}
											validator={requiredValidator}
											data={similarToExistingData}
											value={project.similarToExistingValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onChange={(event) => {
												const value = event.value.text;
												dispatch(
													projectReducers.setRefProjectRequired(value === 'Yes')
												);
											}}
											onFilterChange={(e: any) => {
												const data = project.similarToExistingData.slice();
												setSEPData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_RefProject"
											name="C_RefProject"
											label="Reference Project #"
											component={FormFloatingInput}
											optional={!project.refProjectRequired}
											validator={
												project.refProjectRequired && requiredValidator
											}
											max={50}
										/>
									</div>
								</div>

								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ScheduleKey"
											name="C_ScheduleKey"
											label="Schedule Key"
											component={FormDatePicker}
											validator={requiredValidator}
											wrapperStyle={{ marginRight: '18px' }}
										/>
									</div>
								</div>
							</fieldset>
							<fieldset className="k-form-fieldset">
								<legend className="k-form-legend">Location Details</legend>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectSalesLocation"
											name="C_ProjectSalesLocation"
											label="Sales Location"
											component={FormComboBox}
											validator={requiredValidator}
											data={salesLocationData}
											value={project.salesLocationValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.salesLocationData.slice();
												setSLData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_ProjectDesignLocation"
											name="C_ProjectDesignLocation"
											label="Design Location"
											component={FormComboBox}
											validator={requiredValidator}
											data={designLocationData}
											value={project.designLocationValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onChange={(event) => {
												const value = event.value.text;
												dispatch(
													projectReducers.setDisableDesignTBD(value === 'TBD')
												);
											}}
											onFilterChange={(e: any) => {
												const data = project.designLocationData.slice();
												setDLData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectMfgLocation"
											name="C_ProjectMfgLocation"
											label="Manufacturing Location"
											component={FormComboBox}
											data={mfgLocationData}
											value={project.mfgLocationValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.mfgLocationData.slice();
												setMfgData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_WorkItemAssyLocation"
											name="C_WorkItemAssyLocation"
											label="Assembly Location"
											component={FormComboBox}
											data={assemblyLocationData}
											value={project.assemblyLocationValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.assemblyLocationData.slice();
												setAssemblyData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<Field
									id="C_FOBCityandState"
									name="C_FOBCityandState"
									label="FOB City and State"
									component={FormFloatingInput}
									optional
									max={100}
								/>
							</fieldset>
							<fieldset className="k-form-fieldset">
								<legend className="k-form-legend">Project Team</legend>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_AccountExecutive"
											name="C_AccountExecutive"
											label="Salesperson 1"
											component={FormComboBox}
											validator={requiredValidator}
											data={aeData}
											value={project.aeValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.aeData.slice();
												setAEData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_BRM"
											name="C_BRM"
											label="Salesperson 2"
											component={FormComboBox}
											data={brmData}
											value={project.brmValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.brmData.slice();
												setBRMData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_DM"
											name="C_DM"
											label="Design Manager"
											component={FormComboBox}
											validator={requiredValidator}
											data={dmData}
											value={project.dmValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.dmData.slice();
												setDMData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_SPM"
											name="C_SPM"
											label="Sales Project Manager"
											component={FormComboBox}
											validator={requiredValidator}
											data={spmData}
											value={project.spmValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.spmData.slice();
												setSPMData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_PPM"
											name="C_PPM"
											label="Production Project Manager"
											component={FormComboBox}
											data={ppmData}
											value={project.ppmValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.ppmData.slice();
												setPPMData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_APC"
											name="C_APC"
											label="Assembly Project Coordinator"
											component={FormComboBox}
											data={apcData}
											value={project.apcValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											onFilterChange={(e: any) => {
												const data = project.apcData.slice();
												setAPCData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_StructuralDesigner"
											name="C_StructuralDesigner"
											label="Structural Designer"
											component={FormComboBox}
											data={sdData}
											value={project.sdValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											disabled={project.disableDesignTBD}
											onFilterChange={(e: any) => {
												const data = project.sdData.slice();
												setSDData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_GraphicDesigner"
											name="C_GraphicDesigner"
											label="Graphic Designer"
											component={FormComboBox}
											data={gdData}
											value={project.gdValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											optional
											disabled={project.disableDesignTBD}
											onFilterChange={(e: any) => {
												const data = project.gdData.slice();
												setGDData(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_TeamMember1"
											name="C_TeamMember1"
											label="Team Member 1"
											component={FormComboBox}
											data={tm1Data}
											value={project.tm1Value}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.tm1Data.slice();
												setTM1Data(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_TeamMember2"
											name="C_TeamMember2"
											label="Team Member 2"
											component={FormComboBox}
											data={tm2Data}
											value={project.tm2Value}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.tm2Data.slice();
												setTM2Data(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_TeamMember3"
											name="C_TeamMember3"
											label="Team Member 3"
											component={FormComboBox}
											data={tm3Data}
											value={project.tm3Value}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.tm3Data.slice();
												setTM3Data(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_TeamMember4"
											name="C_TeamMember4"
											label="Team Member 4"
											component={FormComboBox}
											data={tm4Data}
											value={project.tm4Value}
											dataItemKey={api.keyField}
											textField={api.textField}
											optional
											filterable
											onFilterChange={(e: any) => {
												const data = project.tm4Data.slice();
												setTM4Data(filterBy(data, e.filter));
											}}
										/>
									</div>
								</div>
							</fieldset>
							<fieldset className="k-form-fieldset">
								<legend className="k-form-legend">Display Details</legend>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_ProjectDisplayType"
											name="C_ProjectDisplayType"
											label="Display Type"
											component={FormComboBox}
											validator={requiredValidator}
											data={displayTypeData}
											value={project.displayTypeValue}
											dataItemKey={api.keyField}
											textField={api.textField}
											filterable
											onFilterChange={(e: any) => {
												const data = project.displayTypeData.slice();
												setDisplayData(filterBy(data, e.filter));
											}}
										/>
									</div>
									<div style={{ width: '50%' }} />
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_CustomerItemNum"
											name="C_CustomerItemNum"
											label="Customer Item #1"
											component={FormFloatingInput}
											textField="label"
											optional
											max={50}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="SKU"
											name="SKU"
											label="SKU"
											component={FormFloatingInput}
											textField="label"
											optional
											max={50}
										/>
									</div>
								</div>
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div style={{ width: '50%', marginRight: '50px' }}>
										<Field
											id="C_MaterialOrderQty"
											name="C_MaterialOrderQty"
											label="Material Order Qty"
											component={FormFloatingNumericTextBox}
											textField="label"
											optional
											min={0}
										/>
									</div>
									<div style={{ width: '50%' }}>
										<Field
											id="C_AssemblyOrderQty"
											name="C_AssemblyOrderQty"
											label=" Assembly Order Qty"
											component={FormFloatingNumericTextBox}
											textField="label"
											optional
											min={0}
										/>
									</div>
								</div>
							</fieldset>
							{/* {(formRenderProps.visited &&
                                formRenderProps.errors &&
                                Object.entries(formRenderProps.errors).length > 0) ? (
                                <div style={{ paddingTop: '10px' }}>
                                    {
                                        Object.entries(formRenderProps.errors).map(
                                            ([key, value]) => {
                                                return (
                                                    value &&
                                                    <div className={"k-messagebox-error"}>
                                                        {requiredFieldNames[key]}
                                                    </div>
                                                )
                                            }
                                        ).filter((f) => f !== undefined && f !== "").length > 0 ? <div className={"k-messagebox k-messagebox-error"}>
                                            Please fill in below required values
                                            {
                                                Object.entries(formRenderProps.errors).map(
                                                    ([key, value]) => {
                                                        return (
                                                            value &&
                                                            <div >
                                                                {requiredFieldNames[key]}
                                                            </div>
                                                        )
                                                    }
                                                )
                                            }
                                        </div> : ''
                                    }
                                </div>
                            ) : ''
                            } */}
							<div className="k-form-buttons k-buttons-end">
								<Button
									primary
									type="submit"
									onClick={(e: any) => {
										if (formRenderProps.allowSubmit) {
											formRenderProps.allowSubmit =
												formRenderProps.touched && !formRenderProps.valid;
											formRenderProps.onSubmit(e);
										}
									}}
									// disabled={!formRenderProps.allowSubmit}
								>
									Create Project
								</Button>
								{/* <Button onClick={formRenderProps.onFormReset}>
                                Clear
                                </Button> */}
							</div>
						</FormElement>
					)}
				/>
			)}

			<NotificationGroup
				style={{ top: 0, right: 0, alignItems: 'flex-end', zIndex: 999999 }}
			>
				<Fade enter exit>
					{project && project.apiError && (
						<Notification type={{ style: 'error', icon: true }}>
							Error Notification
						</Notification>
					)}
				</Fade>
				<Push
					enter
					exit
					onEntered={() =>
						setTimeout(() => {
							projectReducers.showNotification({
								showNotification: false,
								notificationMessage: '',
								notificationType: null,
							});
						}, 3000)
					}
				>
					{project && project.showNotification && (
						<Notification
							type={{
								style: project.notificationType,
								icon: true,
							}}
							onClose={() =>
								projectReducers.showNotification({
									showNotification: false,
									notificationMessage: '',
									notificationType: null,
								})
							}
						>
							{project && project.notificationMessage}
						</Notification>
					)}
				</Push>
			</NotificationGroup>
			{project.loading && (
				<Loader
					className="loader-wrapper"
					size="large"
					type="converging-spinner"
				/>
			)}
		</div>
	);
};

export default ProjectDetails;
