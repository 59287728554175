import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { signinRedirect } from '../authentication/userManagerService';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Login = () => {
	const query = useQuery();
	const destination = query.get('destination');

	useEffect(() => {
		/**
		 * signinRedirect function will redirect the user to /signin-oidc
		 * /signin-oidc will then redirect the user to /
		 * / will redirect the user to /dashboard
		 */
		// eslint-disable-next-line no-restricted-globals
		signinRedirect(location.search);
	}, [destination]);

	return <div>Logging in...</div>;
};

export default Login;
