import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import projectTasks from '../../services/appData';
import { TaskDetail, TaskSelection } from './task.interface';

/// task related data types
const initialState: TaskDetail = {
	taskListArray: projectTasks,
	popupTaskSelections: [],
	showTasks: false,
	allSelectedTasks: [],
	allSelectedTasksOld: [],
	showAddTaskPopup: false,
	allResourceData: [],
	dropDownData: {},
};

const tasks = createSlice({
	name: 'taskDetail',
	initialState,
	reducers: {
		setPopupTaskSelected(state, { payload }: PayloadAction<any>) {
			const { id, checked, quantity } = payload;
			const index = state.popupTaskSelections.findIndex((e) => e.name === id);
			let tempTasks = state.popupTaskSelections;

			if (index !== -1) {
				// task is present
				// remove it entirely
				tempTasks = tempTasks.filter((value) => {
					return value.name !== id;
				});
			}

			// check for quantity
			if (quantity !== null) {
				for (let i = 0; i < quantity; i += 1) {
					// push the task name
					tempTasks.push({ name: id, quantity });
				}
			} else if (checked) {
				if (index === -1) {
					tempTasks.push({ name: id, quantity: 1 });
				}
			}
			state.popupTaskSelections = tempTasks.sort((a, b) =>
				a.name > b.name ? 1 : -1
			);
		},
		resetPopupTaskSelected(state) {
			const newList = [] as TaskSelection[];
			state.popupTaskSelections = newList.sort((a, b) =>
				a.name > b.name ? 1 : -1
			);
		},
		toggleAddTaskPopup(state, { payload }: PayloadAction<boolean>) {
			state.showAddTaskPopup =
				payload != null ? payload : !state.showAddTaskPopup;
		},
		showTasks(state) {
			state.showTasks = true;
		},
		setAllTasks(state) {
			const tempPopupTasks = state.popupTaskSelections;
			// let taskList: ProjectTask[] = [];
			const taskList = state.allSelectedTasks;
			const sharedFields: any[] = [];
			state.allSelectedTasksOld = [];
			Object.assign(state.allSelectedTasksOld, taskList);
			tempPopupTasks.forEach((element: TaskSelection) => {
				const t = state.taskListArray.find((w) => w.name === element.name);
				if (t !== undefined) {
					taskList.push(t);
					t.fields.forEach((field: any) => {
						if (field.shared) {
							const index = sharedFields.findIndex(
								(e: any) => e.key === field.key
							);
							if (index === -1) {
								sharedFields.push(field);
							}
						}
					});
				}
			});

			state.allSelectedTasks = taskList.sort((a, b) =>
				a.name > b.name ? 1 : -1
			);
			state.sharedFields = sharedFields;
			state.popupTaskSelections = [];
		},
		showNotification(state, { payload }: PayloadAction<any>) {
			if (payload.showNotification) {
				state.showNotification = payload.showNotification;
			}
			if (payload.notificationMessage) {
				state.notificationMessage = payload.notificationMessage;
			}
			if (payload.notificationType) {
				state.notificationType = payload.notificationType;
			}
			if (payload.loading !== undefined) {
				state.loading = payload.loading;
			}
		},
		setLoading(state, { payload }: PayloadAction<any>) {
			state.loading = payload;
		},
		removeAllTasksAtIndex(state, { payload }: PayloadAction<any>) {
			state.allSelectedTasks = state.allSelectedTasks.filter(
				(v, i) => i !== payload
			);
			state.showTasks = state.allSelectedTasks.length !== 0;
		},
		setAllUsers(state, { payload }: PayloadAction<any>) {
			state.allResourceData.push(...payload);
		},
		setSharedFieldsData(state, { payload }: PayloadAction<any>) {
			state.sharedFieldsData = payload;
		},
		setDesignEstimatingMilestone(state, { payload }: PayloadAction<any>) {
			state.designEstimatingMilestone = payload.length > 0 ? payload[0].id : '';
		},
		setDropDownData(state, { payload }: PayloadAction<any>) {
			state.dropDownData[payload.key] = payload.value;
		},
	},
});

export const {
	setPopupTaskSelected,
	toggleAddTaskPopup,
	showTasks,
	setAllTasks,
	showNotification,
	setLoading,
	resetPopupTaskSelected,
	removeAllTasksAtIndex,
	setAllUsers,
	setSharedFieldsData,
	setDesignEstimatingMilestone,
	setDropDownData,
} = tasks.actions;

export default tasks.reducer;
