import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	clzsid: '',
};

export const authorizationSlice = createSlice({
	name: 'authorization',
	initialState,
	reducers: {
		setclzsid: (state, action) => {
			state.clzsid = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setclzsid } = authorizationSlice.actions;

export default authorizationSlice.reducer;
