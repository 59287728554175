import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signinRedirectCallback } from '../authentication/userManagerService';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const SigninOidc = () => {
	const navigate = useNavigate();
	const query = useQuery();
	let destination = query.get('destination');

	useEffect(() => {
		const signinAsync = async () => {
			try {
				await signinRedirectCallback().then((user) => {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					destination = user.state;
				});
				navigate(destination?.replace('?destination=', '') || '/', {
					replace: true,
				});
			} catch (e) {
				navigate('/login', { replace: true });
			}
		};

		signinAsync();
	}, [navigate, destination]);

	return <div>Redirecting...</div>;
};

export default SigninOidc;
