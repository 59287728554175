/* eslint-disable */
// Generated file. DO NOT EDIT.
import { PackageMetadata } from '@progress/kendo-licensing';

/**
 * @hidden
 */
export const packageMetadata: PackageMetadata = {
	name: '@progress/kendo-react-labels',
	productName: 'KendoReact',
	productCodes: ['KENDOUIREACT', 'KENDOUICOMPLETE'],
	publishDate: 0,
	version: '',
	licensingDocsUrl: 'https://www.telerik.com/kendo-react-ui/my-license/',
};
