import React, { useEffect } from 'react';
import { signinSilentCallback } from '../authentication/userManagerService';

function SilentRenewOidc() {
	useEffect(() => {
		async function silentRenew() {
			try {
				await signinSilentCallback();
				// eslint-disable-next-line no-empty
			} catch (e) {}
		}

		silentRenew();
	}, []);

	return <div>Redirecting...</div>;
}

export default SilentRenewOidc;
