import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Customer {
	id: string;
	Name: string;
}

interface CustomerCombosState {
	customer: Customer[];
	billto: Customer[];
	businessUnit: Customer[];
	brand: Customer[];
}

const initialState: CustomerCombosState = {
	customer: [],
	billto: [],
	businessUnit: [],
	brand: [],
};

const customer = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		setCustomers(state, { payload }: PayloadAction<Customer[]>) {
			state.customer.push(...payload);
		},
		setCustomerBillTos(state, { payload }: PayloadAction<Customer[]>) {
			state.billto = payload;
		},
		setBusinessUnit(state, { payload }: PayloadAction<Customer[]>) {
			state.businessUnit = payload;
		},
		setBrand(state, { payload }: PayloadAction<Customer[]>) {
			state.brand = payload;
		},
	},
});

export const { setCustomers, setCustomerBillTos, setBusinessUnit, setBrand } =
	customer.actions;
export default customer.reducer;
